// *******************************************************
// UserStreaks
// -------------------------------------------------------
// This is a Screen for showing list all Streaks
// We want to understand who is getting communcation and are they responding to it.

// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import { format } from 'date-fns';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { differenceInDays } from 'date-fns';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
import { getStreaksByQuery } from 'src/collections/streaks';
import { getAllTracks } from 'src/collections/tracks';
import { getAllUnits } from 'src/collections/units';
// import { AnalyticsType } from 'src/modeltypes/analytics';
import { StreakType } from 'src/modeltypes/streaks';
import { TrackType } from 'src/modeltypes/tracks';
import { UnitType } from 'src/modeltypes/unit';
import { useQuery } from '@tanstack/react-query';
import { getUserById } from 'src/collections/user';
import { useParams } from 'react-router-dom';
import { where } from 'firebase/firestore';
// import { VictoryBar, VictoryChart, VictoryGroup, VictoryPie } from 'victory';
// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

const UserStreaks = () => {
  const [tracks, setTracks] = useState<TrackType[] | null>(null);
  const [units, setUnits] = useState<UnitType[] | null>(null);
  const [currentButton, setCurrentButton] = useState<string | null>('all');

  const { id } = useParams();
  console.log('asdf id: ', id);

  const { data: user } = useQuery({
    queryFn: () => getUserById(id),
    queryKey: ['user'],
    initialData: null,
  });

  const { data: streakssTotal } = useQuery({
    queryFn: () => getStreaksByQuery(where('userId', '==', id)),
    queryKey: ['streaks'],
    initialData: [],
  });

  const today = new Date();

  const buttons = [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'This Month',
      id: 'thisMonth',
    },
    {
      name: 'This Week',
      id: 'thisWeek',
    },
    {
      name: 'Highest Streak',
      id: 'sortByStreak',
    },
  ];

  const data = useMemo(() => {
    let thisData: StreakType[] | null = [];

    if (streakssTotal?.length) {
      if (currentButton === 'thisMonth') {
        thisData = streakssTotal
          ?.filter((x) => differenceInDays(today, x?.createdAt?.toDate()) <= 31)
          .sort((a, b) => {
            const date1 = a?.createdAt && a?.createdAt?.toDate ? a?.createdAt?.toDate() : new Date();
            const date2 = b?.createdAt && b?.createdAt?.toDate ? b?.createdAt?.toDate() : new Date();
            if (date1 && date2) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return date2 - date1;
            }
            return -1;
          });
      }

      if (currentButton === 'thisWeek') {
        thisData = streakssTotal
          ?.filter((x) => differenceInDays(today, x?.createdAt?.toDate()) <= 7)

          .sort((a, b) => {
            const date1 = a?.createdAt && a?.createdAt?.toDate ? a?.createdAt?.toDate() : new Date();
            const date2 = b?.createdAt && b?.createdAt?.toDate ? b?.createdAt?.toDate() : new Date();
            if (date1 && date2) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return date2 - date1;
            }
            return -1;
          });
      }

      if (currentButton === 'all') {
        thisData = streakssTotal.sort((a, b) => {
          const date1 = a?.createdAt && a?.createdAt?.toDate ? a?.createdAt?.toDate() : new Date();
          const date2 = b?.createdAt && b?.createdAt?.toDate ? b?.createdAt?.toDate() : new Date();
          if (date1 && date2) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return date2 - date1;
          }
          return -1;
        });
      }
      if (currentButton === 'sortByStreak') {
        thisData = streakssTotal.sort((a, b) => {
          const streak1 = a?.streakNumber;
          const streak2 = b?.streakNumber;
          if (streak1 && streak2) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return streak2 - streak1;
          }
          return 1;
        });
      }
    }
    return thisData;
  }, [currentButton, today]);

  console.log('data?.length: ', data?.length);

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  useEffect(() => {
    getAllTracks().then((fTracks) => setTracks(fTracks));
    getAllUnits().then((fUnits) => setUnits(fUnits));
  }, []);
  return (
    <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div>
        <p>User:</p>
        <p className='font-bold text-xl'>{user?.email}</p>
        <p className='font-bold text-xl'>{user?.id}</p>
        <p className='font-bold text-xl'>
          {user?.firstName} {user?.lastName}
        </p>
      </div>

      <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <thead className='bg-gray-50'>
            <tr>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Total Streaks {streakssTotal?.length}
              </th>
              {buttons.map((button) => (
                <th key={button.name}>
                  <div className='flex justify-center'>
                    <button
                      onClick={() => {
                        console.log('imonthly ');
                        setCurrentButton(button?.id);
                      }}
                      className={classNames(
                        button.id === currentButton
                          ? 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900  bg-gray-900 text-white'
                          : 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900',
                      )}
                    >
                      {button.name}
                    </button>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        </div>
        {/* <VictoryChart>
          <VictoryGroup offset={20} colorScale={'qualitative'}>
            <VictoryBar
              data={Object.keys(streaksTypeValues || {}).map((f) => {
                return { x: getLabel(f), y: streaksTypeValues?.[f] || 0 };
              })}
              labels={Object.keys(streaksTypeValues || {}).map(() => {
                return 'Total';
              })}
              // horizontal={true}
            />
            <VictoryBar
              data={Object.keys(streaksExpiredTypeValues || {}).map((f) => {
                return { x: getLabel(f), y: streaksExpiredTypeValues?.[f] || 0 };
              })}
              labels={Object.keys(streaksExpiredTypeValues || {}).map(() => {
                return 'Expired';
              })}
            />
            <VictoryBar
              data={Object.keys(streaksActiveTypeValues || {}).map((f) => {
                return { x: getLabel(f), y: streaksActiveTypeValues?.[f] || 0 };
              })}
              labels={Object.keys(streaksActiveTypeValues || {}).map(() => {
                return 'Active';
              })}
            />
          </VictoryGroup>
        </VictoryChart> */}

        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
              <tr>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Id
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  User Id
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Streak
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Streak Started
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Streak Ended Date
                </th>
                <th scope='col' className='py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6'>
                  Streak Minutes
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Highest Streak to Date
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  Tracks Included
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  Units Included
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {!data?.length ? (
                <div>No Streaks during this timeframe</div>
              ) : (
                data?.map((streak, idx) => {
                  // console.log('asdfasdf streak: ', streak);
                  const uniqueTracks = [...new Set(streak?.trackIds || [])];
                  const uniqueUnits = [...new Set(streak?.unitIds || [])];

                  return (
                    <tr key={idx} className='hover:bg-stone-200'>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='font-medium text-gray-900'>{streak.id}</div>
                      </td>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='font-medium text-gray-900'>{streak.userId}</div>
                      </td>

                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='font-medium text-gray-900'>{streak.streakNumber}</div>
                      </td>

                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        {streak?.dateStreakStarted
                          ? format(streak?.dateStreakStarted?.toDate(), 'dd MMM yyyy hh:mm aa')
                          : '-'}
                      </td>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        {streak?.dateStreakEnded
                          ? format(streak.dateStreakEnded.toDate(), 'dd MMM yyyy hh:mm aa')
                          : '-'}
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <div className='font-medium text-gray-900'>{Math.ceil(streak?.totalMinutes || 0)}</div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <div className='font-medium text-gray-900'>{streak?.highestStreak}</div>
                      </td>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='font-medium text-gray-900'>
                          {!tracks?.length
                            ? null
                            : uniqueTracks.map((t: string) => {
                                return <div key={t}>{tracks?.find((t2: TrackType) => t2.id === t)?.title}</div>;
                              })}
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <div className='font-medium text-gray-900'>
                          {!units?.length
                            ? null
                            : uniqueUnits.map((t: string) => {
                                return <div key={t}>{units?.find((t2: UnitType) => t2.id === t)?.title}</div>;
                              })}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserStreaks;
