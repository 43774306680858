// *******************************************************
// Cohort CohortScienceTimeDownloadPage
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

// *******************************************
// Component Imports
// -------------------------------------------
import { ChevronLeftIcon } from '@heroicons/react/solid';

// *******************************************
// Hooks Import
// -------------------------------------------
// *******************************************
// Action Imports
// -------------------------------------------
import { getUserDetailsByIds, getUserIdsByCohortId } from '../../collections/user';
import { getCohortById } from '../../collections/cohort';
import { getAllSessionsForUserIdArray, getSessionsForUserIdArray } from '../../collections/session';

// *******************************************
// Styles Imports
// -------------------------------------------
// *******************************************
// Constants
// -------------------------------------------
// *******************************************
// Types
// -------------------------------------------
// import { ExperienceTs, GoalTs } from 'src/enums/goals';

import { useAppContext } from '../../contexts/appContext';
import {
  CohortId,
  // RespirationDataId,
  UserId,
} from '../../modeltypes/id';
import { UserDetailsType } from '../../modeltypes/composed';
import { SessionType } from '../../modeltypes/session';
import { CohortType } from '../../modeltypes/cohort';
import { LessonType } from '../../modeltypes/lesson';
import { TrackType } from '../../modeltypes/tracks';
import { getLessonsByIdArray } from '../../collections/lesson';
import { getTracksByIdArray } from '../../collections/tracks';
import { TaskType } from '../../modeltypes/tasks';
import { getTasksByIdArray } from '../../collections/tasks';
import { getAllLessonAudioChunks } from '../../collections/lessonAudioChunk';
import { getChallengesByIdArray } from '../../collections/challenges';
import BpmPopupChart, { BPmPopupChartType } from '../BpmPopupChart';
import { getAllUnits } from 'src/collections/units';
import { UnitType } from 'src/modeltypes/unit';
import { ChallengeType } from 'src/modeltypes/challenges';
import { LessonAudioChunkType } from 'src/modeltypes/lessonAudioChunk';
import SelectedSessionsCsvDownload from '../users/SelectedSessionsCsvDownload';

const CohortScienceTimeDownloadPage = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: CohortId }>();
  const [cohort, setCohort] = useState<CohortType>();
  const [usersIds, setUsersIds] = useState<UserId[]>();
  const [userDetails, setUserDetails] = useState<UserDetailsType[]>();
  const [sessions, setSessions] = useState<SessionType[] | null>(null);

  const [nodes, setNodes] = useState<LessonType[] | null>(null);
  const [challenges, setChallenges] = useState<ChallengeType[] | null>(null);
  const [tracks, setTracks] = useState<TrackType[] | null>(null);
  const [units, setUnits] = useState<UnitType[] | null>(null);
  const [tasks, setTasks] = useState<TaskType[] | null>(null);

  const [runCsv, setRunCsv] = useState<boolean>(false);
  const [audioChunks, setAudioChunks] = useState<LessonAudioChunkType[] | null>(null);
  const [totalSessions, setTotalSessions] = useState<SessionType[] | null>(null);

  const [showFullAccFile, setShowFullAccFile] = useState<boolean>(false);
  const [showFullGyroFile, setShowFullGyroFile] = useState<boolean>(false);

  const [scienceSelections, setScienceSelections] = useState<string[]>([]);

  console.log('asdf scienceSelections: ', scienceSelections);

  console.log('asdf nodes: ', nodes);
  console.log('asdf audioChunks: ', audioChunks);
  console.log('asdf tracks: ', tracks);
  console.log('asdf tasks: ', tasks);
  console.log('asdf totalSessions: ', totalSessions);
  // For BPM Popups
  // For the Respiration Data
  const [showBPM, setShowBPM] = useState<boolean>(false);
  const initialBPMData = {
    plot1: null,
  };
  const [bpmPopupData, setBPMPopupData] = useState<BPmPopupChartType | null>(initialBPMData);

  const { setBreadcrumbPaths } = useAppContext();

  const addItems = (prev?: number | null, next?: number | null) => (prev || 0) + (next || 0);

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Cohorts',
        path: '/cohorts',
      },
      {
        label: 'Science Time Based Download',
        path: `/cohorts/science-time-download/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  useEffect(() => {
    if (id) {
      getCohortById(id).then((cohortDB) => {
        if (cohortDB) {
          setCohort(cohortDB);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (cohort && cohort.id) {
      console.log(cohort);
      getUserIdsByCohortId(cohort.id, cohort?.isTestCohort).then((ids) => {
        setUsersIds(ids);
      });
    }
  }, [cohort]);

  useEffect(() => {
    if (usersIds && usersIds.length > 0 && cohort && cohort.creationDate) {
      getSessionsForUserIdArray(usersIds, cohort.creationDate).then((sessionsDB) => {
        setSessions(sessionsDB.flat());
      });

      getAllSessionsForUserIdArray(usersIds).then((sessionsDB) => {
        setTotalSessions(sessionsDB.flat());
      });
    }

    if (usersIds) {
      getUserDetailsByIds(usersIds).then((usersDetailsDb) => {
        setUserDetails(
          usersDetailsDb.sort((user, anotherUser) => user.user.lastName.localeCompare(anotherUser.user.lastName)),
        );
        const contentIds = usersDetailsDb
          .map(({ user }) => ({
            track: user.currentLesson?.trackId,
            nodeId: user.currentLesson?.nodeId,
            // type: user.currentLesson?.type,
          }))
          .reduce(
            (object: { tracks: string[]; units: string[]; nodes: string[] }, el) => {
              if (el.track) object.tracks.push(el.track);
              if (el.nodeId) object.nodes.push(el.nodeId);

              return object;
            },
            {
              tracks: [],
              units: [],
              nodes: [],
            },
          );

        getLessonsByIdArray([...new Set(contentIds.nodes)]).then((fNodes) => setNodes([...(nodes || []), ...fNodes]));
        getTasksByIdArray([...new Set(contentIds.nodes)]).then((fNodes) => setTasks(fNodes));
        // getLessonAudioChunksByIdArray([...new Set(contentIds.nodes)]).then((chunks) =>
        //   setAudioChunks([...(audioChunks || []), ...chunks]),
        // );
        getChallengesByIdArray([...new Set(contentIds.nodes)]).then((fChallenges) =>
          setChallenges([...(challenges || []), ...fChallenges]),
        );

        getTracksByIdArray([...new Set(contentIds.tracks)]).then((fTracks) => setTracks(fTracks));
        // getUnitsByIdArray([...new Set(contentIds.units)]).then((fUnits) => setUnits(fUnits));
      });
    }
  }, [usersIds, cohort]);

  useEffect(() => {
    getAllLessonAudioChunks().then((fTracks) => setAudioChunks(fTracks));
  }, []);

  type GoalStats = {
    goal: string;
    count: number;
  };

  type ExperStats = {
    experience: string;
    count: number;
  };

  useEffect(() => {
    getAllUnits().then((fUnits) => setUnits(fUnits));
  }, []);

  useEffect(() => {
    if (userDetails) {
      // console.log('userDetails: ', userDetails);

      const tempGoalStats: Record<string, GoalStats> = {};
      const tempExperienceStats: Record<string, ExperStats> = {};

      for (const { user } of userDetails) {
        const experience: string | [key: string] = user?.experience || 'NotKnow';
        const goal: string | [key: string] = user?.goal || 'NotKnow';

        tempExperienceStats[experience] = {
          experience: experience,
          count: tempExperienceStats?.[experience] ? tempExperienceStats[experience].count + 1 : 1,
        };

        tempGoalStats[goal] = {
          goal: goal,
          count: tempGoalStats[goal] ? tempGoalStats[goal].count + 1 : 1,
        };
      }

      console.log('tempGoalStats: ', tempGoalStats);
      console.log('tempExperienceStats: ', tempExperienceStats);
    }
  }, [userDetails]);

  const prepareTotalTime = (): number =>
    userDetails && userDetails.length > 0
      ? userDetails
          .map(({ calendar }) =>
            calendar
              ?.map(({ value: { hits, meditation, meditationTimes } }) => {
                const medTime =
                  meditationTimes !== undefined && meditationTimes.length > 0
                    ? meditationTimes?.map(({ time }) => Math.round(time / 60))?.reduce((prev, next) => prev + next)
                    : 0;
                return (
                  Math.floor((meditation || 0) / 60) +
                  (hits?.formal || 0) +
                  (hits?.microhit || 0) +
                  (hits?.background || 0) +
                  (medTime || 0)
                );
              })
              .reduce((previousValue, currentValue) => previousValue + currentValue),
          )
          .reduce(addItems, 0)
      : 0;
  console.log('prepareTotalTime: ', prepareTotalTime);

  const scienceSelectionClickable = scienceSelections?.length > 0 && runCsv !== true;

  return (
    <div>
      <div className={'mb-10 text-center flex-col items-center flex'}>
        <h3 className='text-3xl leading-6 font-medium text-gray-900  '>{cohort?.name}</h3>
      </div>

      <div className='mt-10 '>
        <div className='sm:flex sm:items-center mb-8'>
          <div className='sm:flex-auto'>
            <h1 className='text-xl font-semibold text-gray-900'>Session Feed</h1>
          </div>
        </div>

        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 text-center'>
          <button
            onClick={() => {
              setRunCsv(true);

              console.log('asdf');
            }}
            disabled={!scienceSelectionClickable}
            type='button'
            className={` ${scienceSelectionClickable ? 'bg-gray-800' : 'bg-gray-300'} 'hover:bg-sky-700' : 'opacity-25'
            } inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:w-auto`}
          >
            Download CSV
          </button>
        </td>

        <div className='mt-8 flex flex-col px-4 sm:px-6 lg:px-8 mb-10'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>Selected</p>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'></td>
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>Item</p>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'></td>
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>Created At</p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>User</p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>AppVersion</p>
                      </th>

                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>Lesson</p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>Lesson Id</p>
                      </th>

                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>Path Type</p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>Duration</p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-100 bg-gray-100'
                      >
                        <p className='group inline-flex'>Accel Data</p>
                        <button
                          onClick={() => setShowFullAccFile(!showFullAccFile)}
                          className={`${
                            showFullAccFile ? 'rotate-180' : ''
                          } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                        </button>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>Accel BPM (Raw)</p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                      >
                        <p className='group inline-flex'>Accel BPM (Smoothed)</p>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        <p className='group inline-flex'>Gryo Data</p>
                        <button
                          onClick={() => setShowFullGyroFile(!showFullGyroFile)}
                          className={`${
                            showFullGyroFile ? 'rotate-180' : ''
                          } ml-2 w-20 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                        >
                          <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                        </button>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        ML Version
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Session Id
                      </th>
                      <th scope='col' className='px-3 py-3.5 texst-left text-sm font-semibold text-gray-900'>
                        Time in Lesson (sec)
                      </th>
                      <th scope='col' className='px-3 py-3.5 texst-left text-sm font-semibold text-gray-900'>
                        Time in Lesson (min)
                      </th>

                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Disturbed
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        <p>Finished Lesson</p>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        <p>Save Time</p>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        <p>Upload Time</p>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        <p>ML Calc Time</p>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        <p>Disturbance Calc Time</p>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        <p>Errors</p>
                      </th>
                    </tr>
                  </thead>

                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {sessions && userDetails ? (
                      sessions
                        .map((session) => format(session.sessionStart.toDate(), 'dd EE MMMM'))
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .map((section) => {
                          const filteredSessions = sessions.filter(
                            (s) => format(s.sessionStart.toDate(), 'dd EE MMMM') === section,
                          );

                          return filteredSessions.map((session, index) => {
                            const user = userDetails.find(({ account }) => account.uid === session.uid);
                            const unit = units?.find((unit) => unit?.id === session?.unitId);
                            const element = unit?.elements?.find((el) => el.id === session.nodeId);

                            if (!element?.pathType) {
                              console.log('session.nodeId: ', session.nodeId);
                              console.log('unit: ', unit);
                              console.log('element: ', element);
                            }

                            console.log('nodes: ', nodes);

                            const lesson =
                              nodes?.find((el) => el.id === session?.nodeId) ||
                              audioChunks?.find((el) => el.id === session?.nodeId) ||
                              null;

                            console.log('lesson: ', lesson);

                            const isClicked = scienceSelections?.includes(session?.id);

                            return (
                              <tr key={session.id} className='hover:bg-stone-200'>
                                <td className='flex-row whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='flex items-center'>
                                    <div className='ml-4'>
                                      <input
                                        type={'checkbox'}
                                        checked={isClicked}
                                        onChange={(event) => {
                                          if (event.target.checked) {
                                            setScienceSelections([...scienceSelections, session.id]);
                                          } else {
                                            setScienceSelections(scienceSelections.filter((id) => id !== session.id));
                                          }
                                          console.log('event : ', event);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>

                                <td className='flex-row whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='ml-4'>
                                    <div className='text-gray-500'>{index + 1}</div>
                                  </div>
                                </td>

                                <td className='whitespace-nowrap py-4 pl-4 pr-3e text-sm sm:pl-6'>
                                  <div className='flex items-center'>
                                    <div className='ml-4'>
                                      <div className='text-gray-500'>
                                        {format(session?.createdAt?.toDate() || Date.now(), 'dd MMMM  yy, HH:mm')}
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {user?.account?.email}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {user?.account?.appVersion}
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {session?.nameOfSession?.length > 20
                                    ? `${session?.nameOfSession.substring(0, 20)}...`
                                    : session?.nameOfSession}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{lesson?.id}</td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {element?.pathType}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {lesson?.duration}
                                </td>

                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                  <div
                                    className={`text-indigo-600 ${
                                      session.accelFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                                    }`}
                                    onClick={() => {
                                      // if (session?.accelFile) {
                                      //   const acceloRef = session?.accelFile ? getStorageRef(session?.accelFile) : null;
                                      //   toastWrap(downloadFile(acceloRef));
                                      // }
                                    }}
                                  >
                                    {typeof session?.accelFile === 'string' && showFullAccFile
                                      ? session?.accelFile
                                      : typeof session?.accelFile === 'string'
                                      ? session?.accelFile.substring(0, 10)
                                      : '-'}
                                    {/* {typeof session?.accelFile === 'string' ? session?.accelFile : '-'} */}
                                  </div>
                                </td>

                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                  <div
                                    className={`text-indigo-600 ${
                                      session.accelPreProcessed ? 'hover:text-indigo-900 cursor-pointer' : ''
                                    }`}
                                    onClick={() => {
                                      if (session?.accelPreProcessed?.length) {
                                        setBPMPopupData({
                                          plot1: session?.accelRespirationData,
                                          plot2: session?.accelPreProcessed,
                                          sessionData: session,
                                        });
                                        setShowBPM(true);
                                      }
                                    }}
                                  >
                                    {session?.accelPreProcessed?.length ? 'Yes' : '-'}
                                  </div>
                                </td>

                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                  <div
                                    className={`text-indigo-600 ${
                                      session.accelRespirationData ? 'hover:text-indigo-900 cursor-pointer' : ''
                                    }`}
                                    onClick={() => {
                                      if (session?.accelRespirationData?.length) {
                                        setBPMPopupData({
                                          plot1: session?.accelRespirationData || [],
                                          plot2: session?.accelPreProcessed || [],
                                          sessionData: session,
                                        });
                                        setShowBPM(true);
                                      }
                                    }}
                                  >
                                    {session?.accelRespirationData?.length ? 'Yes' : '-'}
                                  </div>
                                </td>

                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                  <div
                                    className={`text-indigo-600 ${
                                      session.gyroFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                                    }`}
                                    onClick={() => {
                                      // if (session?.gyroFile) {
                                      //   const gyrooRef = session?.gyroFile ? getStorageRef(session?.gyroFile) : null;
                                      //   toastWrap(downloadFile(gyrooRef));
                                      // }
                                    }}
                                  >
                                    {typeof session?.gyroFile === 'string' && showFullGyroFile
                                      ? session?.gyroFile
                                      : typeof session?.gyroFile === 'string'
                                      ? session?.gyroFile.substring(0, 10)
                                      : '-'}
                                  </div>
                                </td>

                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                  <div className={`text-indigo-600`}>{session?.mlVersion}</div>
                                </td>

                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                  <div className={`text-indigo-600`}>{session?.id}</div>
                                </td>

                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                  <div
                                    className={`text-indigo-600 `}
                                    onClick={() => {
                                      navigate(`/rawData/${session.id}/${session.id}`);
                                    }}
                                  >
                                    {session?.timeInLesson}
                                  </div>
                                </td>

                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                                  <div className={`text-indigo-600 `}>
                                    {session?.timeInLesson ? (session?.timeInLesson / 60).toFixed(1) : '-'}
                                  </div>
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-500'>{}</div>
                                  {typeof session?.disturbanceDetected === 'boolean'
                                    ? `${session.disturbanceDetected}`
                                    : '-'}
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-500'>{`${session?.lessonFinished}`}</div>
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-500'>{`${session?.accelerometerFlowStatus?.saveDuration}`}</div>
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-500'>
                                    {session?.accelerometerFlowStatus?.uploadDuration}
                                  </div>
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-500'>{session?.mlDuration}</div>
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-500'>{session?.disturbanceDuration}</div>
                                </td>

                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-500'>
                                    {session?.accelerometerFlowStatus?.saveError ||
                                      session?.accelerometerFlowStatus?.uploadError}
                                  </div>
                                </td>
                              </tr>
                            );
                          });
                        })
                    ) : (
                      <Skeleton count={5} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBPM ? (
        <BpmPopupChart
          {...bpmPopupData}
          onPressClose={() => {
            setShowBPM(false);
            setBPMPopupData(initialBPMData);
          }}
        />
      ) : null}
      {runCsv && scienceSelections?.length ? (
        <SelectedSessionsCsvDownload
          sessionIds={scienceSelections}
          seshData={sessions?.filter((s) => scienceSelections.includes(s.id)) || []}
          users={userDetails || null}
          tracks={tracks}
          units={units}
          cohortId={cohort?.id || null}
          onClose={() => {
            setRunCsv(false);
            setScienceSelections([]);
          }}
        />
      ) : null}
    </div>
  );
};

export default CohortScienceTimeDownloadPage;
