import { where } from '../models/dalaccess';
import { LESSON_RATING_TABLE_NAME } from './tableName';
import {
  LessonRatingType,
  LessonRatingTypeBase,
  LessonRatingTypeBuilder,
  LessonRatingTypeNew,
} from '../modeltypes/lessonRating';
import { CohortId, CompanyId, LessonRatingId, UserId } from '../modeltypes/id';
import { idToString } from '../utility/GeneralUtilities';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  updateDocumentFor,
  watchIdSetFor,
} from './shared';

// noinspection JSUnusedGlobalSymbols
export const getNewLessonRatingId = getNewIdFor<LessonRatingId>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewLessonRating = addNewFor<LessonRatingId, LessonRatingTypeNew>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewLessonRating = createNewFor<LessonRatingId, LessonRatingTypeBuilder>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonRatingRefById = getRefByIdFor<LessonRatingId>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonRatingById = getByIdFor<LessonRatingId, LessonRatingType>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonRatingsByIdArray = getByIdArrayFor<LessonRatingId, LessonRatingType>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllLessonRatings = getAllFor<LessonRatingType>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllLessonRatingsQuery = getAllQueryFor(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getLessonRatingsByQuery = getByQueryFor<LessonRatingType>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const updateLessonRating = updateDocumentFor<LessonRatingId, LessonRatingTypeBase>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteLessonRating = deleteDocumentFor<LessonRatingId>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const watchLessonRatingIdSet = watchIdSetFor<LessonRatingId>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonRatingType = getValidateTypeFor<LessonRatingType>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonRatingTypeBase = getValidateTypeBaseFor<LessonRatingTypeBase>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonRatingTypeBuilder =
  getValidateTypeBuilderFor<LessonRatingTypeBuilder>(LESSON_RATING_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateLessonRatingTypeNew = getValidateTypeNewFor<LessonRatingTypeNew>(LESSON_RATING_TABLE_NAME);

export const getLessonRatingsByCohort = async (cohortId: CohortId) => {
  const ratings = await getLessonRatingsByQuery(where('cohort', '==', idToString(cohortId)));
  return ratings;
};

export const getLessonRatingsByCompany = async (companyId: CompanyId) => {
  const ratings = await getLessonRatingsByQuery(where('company', '==', idToString(companyId)));
  return ratings;
};

export const getLessonRatingDataByUserId = async (userId: UserId) => {
  const lessonRating = await getLessonRatingsByQuery(where('user', '==', idToString(userId)));
  console.log('returning lessonRating: ', lessonRating);
  return lessonRating;
};
