// *******************************************************
// SelectedSessionsCsvDownload
// -------------------------------------------------------
// This is a SelectedSessionsCsvDownload
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CohortId } from 'src/modeltypes/id';
import { getAllLessonAudioChunks } from '../../collections/lessonAudioChunk';
import { prepareCSVforSessions } from '../../utility/csvUtility';

import { toast } from 'react-toastify';
import JSZip from 'jszip';
import { getBlob } from 'firebase/storage';
import { getStorageRef } from '../../firebase/utils';

import { saveAs } from 'file-saver';
import { LessonAudioChunkType } from 'src/modeltypes/lessonAudioChunk';
import { TrackType } from 'src/modeltypes/tracks';
import { UnitType } from 'src/modeltypes/unit';
import { AfterSessionType, getAfterSessionsWithRef } from 'src/collections/afterSessionSurvey';
import Skeleton from 'react-loading-skeleton';
import { getLessonRatingDataByUserId } from 'src/collections/lessonRating';
import { SessionType } from 'src/modeltypes/session';
import { UserDetailsType } from 'src/modeltypes/composed';
import { CSVDownload } from 'react-csv';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
const SelectedSessionsCsvDownload = ({
  sessionIds,
  seshData,
  cohortId,
  users,
  tracks,
  units,
  onClose,
}: {
  cohortId: string | null;
  sessionIds: string[];
  seshData: SessionType[] | null;
  users: UserDetailsType[] | null;
  tracks: TrackType[] | null;
  units: UnitType[] | null;
  onClose: () => void;
}) => {
  console.log('SelectedSessionsCsvDownload');

  // For the Respiration Data
  const initialScienceData = { node: null, user: null, unit: null, scienceData: null, unitIndex: null };

  const [isPreparingSensorsData, setIsPreparingSensorsData] = useState(false);

  const [audioChunks, setAudioChunks] = useState<LessonAudioChunkType[] | null>(null);
  const [afterSessions, setAfterSessions] = useState<AfterSessionType[] | null>(null);
  const [beforeCCE, setBeforeCCE] = useState<AfterSessionType[] | null>(null);
  const [csvData, setCsvData] = useState<any | null>(null);

  const [downloadCsvData, setDownlaodcsvData] = useState<boolean>(false);

  console.log('isPreparingSensorsData', isPreparingSensorsData);
  console.log('audioChunks', audioChunks?.length);
  console.log('tracks', tracks?.length);
  console.log('initialScienceData', initialScienceData);
  console.log('units', units?.length);

  useEffect(() => {
    if (csvData && downloadCsvData) {
      setCsvData(null);
    }
  }, [csvData, downloadCsvData]);

  const { data: ratingData } = useQuery({
    queryFn: () => getLessonRatingDataByUserId(cohortId as CohortId),
    queryKey: ['lessonRatingData'],
    initialData: null,
  });

  useEffect(() => {
    // noinspection DuplicatedCode
    const prepareSurveysData = async () => {
      if (seshData) {
        const afterSessionsRefs = seshData
          .filter((session) => session.afterSessionSurvey)
          .map((session) => session.afterSessionSurvey);

        const beforeCCE = seshData.filter((session) => session.beforeCCE).map((session) => session.beforeCCE);

        const afterSessions = await Promise.all(
          afterSessionsRefs.map(async (ref) => getAfterSessionsWithRef(ref)),
        ).then((asArray) => asArray.filter((el): el is AfterSessionType => !!el?.id));

        const bCCE = await Promise.all(beforeCCE.map(async (ref) => getAfterSessionsWithRef(ref))).then((bcceArray) =>
          bcceArray.filter((el): el is AfterSessionType => !!el?.id),
        );

        return {
          afterSessions,
          bCCE,
        };
      }
      return Promise.resolve({
        afterSessions: [],
        bCCE: [],
      });
    };
    prepareSurveysData().then((surveysData) => {
      setAfterSessions(surveysData.afterSessions);
      setBeforeCCE(surveysData.bCCE);
    });
  }, [seshData]);

  const runDownloadCsvData = async () => {
    const thisCsvData = users?.length
      ? prepareCSVforSessions({
          afterSessions,
          beforeCCE,
          sessions: seshData,
          users: users || null,
          cohortLessonRatings: ratingData,
        })
      : null;
    setCsvData(thisCsvData);
  };

  const downloadZippedSensorsData = async () => {
    setIsPreparingSensorsData(true);

    const sessionsWithFiles = seshData?.filter((x) => x.accelFile || x.gyroFile);
    console.log('Inside downloadZippedSensorsData ratingData', ratingData);
    console.log('Inside downloadZippedSensorsData seshData?.length ', seshData?.length);
    console.log('Inside downloadZippedSensorsData sessionsWithFiles?.length ', sessionsWithFiles?.length);

    if (sessionsWithFiles?.length) {
      toast.info('Preparing sensors files to be downloaded', {
        toastId: 'sensors',
        autoClose: false,
        closeOnClick: false,
        isLoading: true,
        progress: 0,
      });
      const zip = new JSZip();

      const thisCsvData = users?.length
        ? prepareCSVforSessions({
            afterSessions,
            beforeCCE,
            sessions: seshData,
            users: users || null,
            cohortLessonRatings: ratingData,
          })
        : null;
      setCsvData(thisCsvData);

      for (const idx in sessionsWithFiles) {
        const index = +idx;
        toast.update('sensors', { progress: (index + 1) / sessionsWithFiles.length });
        const session = sessionsWithFiles[index];
        const accelerometerRef = session.accelFile ? getStorageRef(session.accelFile) : null;
        const gyroRef = session.gyroFile ? getStorageRef(session.gyroFile) : null;
        const audioRef = session.audioFile ? getStorageRef(session.audioFile) : null;

        if (accelerometerRef) {
          const accelFile = await getBlob(accelerometerRef);
          const accelString = await accelFile.text();
          const accelData = JSON.parse(accelString);
          const fullData = {
            accelerometer: accelData,
          };
          console.log('about to get fileWithCsv', session.accelFile);
          const fileWithCsv = await new Blob([JSON.stringify(fullData, null, 20)]);

          const fileBreakdown = session?.accelFile?.split('/');

          const fileName = fileBreakdown?.[fileBreakdown?.length - 2];
          console.log('about to zip file fileWithCsv', session.accelFile);
          try {
            zip.file(`accelerometer/${fileName}-${accelerometerRef.name}`, fileWithCsv, {
              comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
            });
          } catch (error) {
            console.log('Zip Failed for , ', fileName, ' error - ', error);
          }
        } else {
          console.log(`session ${session.id}, doesnt have accel data`);
        }

        if (gyroRef) {
          const gyroFile = await getBlob(gyroRef);
          const gyroString = await gyroFile.text();
          const gyroData = JSON.parse(gyroString);
          const fullData = {
            gyroscope: gyroData,
          };
          const fileWithCsv = new Blob([JSON.stringify(fullData, null, 20)]);
          zip.file(`gyroscope/${session.id}-${gyroRef.name}`, fileWithCsv, {
            comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
          });
        } else {
          console.log(`session ${session.id}, doesnt have gyro data`);
        }

        if (audioRef) {
          const audioFile = await getBlob(audioRef);
          zip.file(`audio/${session.id}-${audioRef.name}`, audioFile, {
            comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
          });
        } else {
          console.log(`session ${session.id}, doesnt have audio data`);
        }

        if (thisCsvData) {
          // const csvSession = thisCsvData?.find((el) => el.sessionId === session.id) || {};
          const fullData = [...thisCsvData];

          const fileWithCsv = new Blob([JSON.stringify(fullData, null, 20)]);

          zip.file(`data.json`, fileWithCsv, {
            comment: `Zipping the Csv`,
          });
        } else {
          console.log(`----------------------No CSV Data!!!!!!!!-------------------`);
        }
      }

      if (Object.values(zip.files).length > 0) {
        toast.info('Zipping files...', {
          toastId: 'zipping',
          isLoading: true,
          closeOnClick: false,
        });
        zip
          .generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
              /* compression level ranges from 1 (best speed) to 9 (best compression) */
              level: 9,
            },
          })
          .then(function (blob) {
            saveAs(blob, `selected-sessions-${new Date().toISOString().replace('T', '/').split('.')[0]}`);
            toast.update('zipping', {
              type: 'success',
              render: 'Downloaded zipped sensors data...',
              closeOnClick: true,
              isLoading: false,
              autoClose: 5000,
            });
          });
      } else {
        toast.update('zipping', {
          type: 'error',
          render: 'There is no sensors files to be downloaded',
          closeOnClick: true,
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
    setIsPreparingSensorsData(false);
  };

  useEffect(() => {
    getAllLessonAudioChunks().then((fTracks) => setAudioChunks(fTracks));
  }, []);

  if (!sessionIds?.length) {
    return null;
  }

  return (
    <div
      id='defaultModal'
      tabIndex={-1}
      aria-hidden='true'
      className='flex pt-[2%] justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-gray-600 bg-opacity-50 z-50 w-full h-full md:inset-0 h-modal'
    >
      <div className='relative  w-full max-w-3xl h-full md:h-auto'>
        <div
          className='relative bg-white rounded-lg shadow dark:bg-gray-700'
          style={{ backgroundColor: '#EEE', padding: 20 }}
        >
          <button
            type='button'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
            onClick={() => (onClose ? onClose() : null)}
          >
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
            <span className='sr-only'>Close modal</span>
          </button>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <h1 style={{ alignSelf: 'center', flex: 1 }}>Downloading {sessionIds?.length} sessions</h1>
            {isPreparingSensorsData ? (
              <Skeleton circle height={50} width={50} style={{ backgroundColor: 'gray' }} />
            ) : null}

            {downloadCsvData && csvData && (
              <CSVDownload
                data={csvData}
                asyncOnClick
                // onClick={(x, done) => {
                onClick={(x, done) => {
                  console.log('x', x);
                  console.log('done', done);
                  setCsvData(null);
                  setDownlaodcsvData(false);
                }}
              />
            )}
            <button
              disabled={isPreparingSensorsData}
              className='mt-4 text-indigo-400 hover:text-indigo-700 font-bold mr-50'
              onClick={() => {
                setDownlaodcsvData(true);
                runDownloadCsvData();
              }}
            >
              Download Csv Data
            </button>

            <button
              disabled={isPreparingSensorsData}
              className='mt-4 text-indigo-400 hover:text-indigo-700 font-bold'
              onClick={() => downloadZippedSensorsData()}
            >
              Download Sensors data
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedSessionsCsvDownload;
