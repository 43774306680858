import { SurveyType } from '../modeltypes/surveys';
import { SurveyId, UserId } from '../modeltypes/id';
import { getByIdArrayFor, prepareChunks } from './shared';
import { SURVEY_TABLE_NAME } from './tableName';
import { collection, DocumentReference, getDoc, getDocs, query, where } from '../models/dalaccess';

export const getSurveyWithRef = async (ref: DocumentReference): Promise<SurveyType | null> => {
  const survey = await getDoc(ref);

  if (!survey.exists()) {
    return null;
  }
  return {
    ...(survey.data() as SurveyType),
    id: survey.id,
  };
};

export const getSurveysByIdArray = getByIdArrayFor<SurveyId, SurveyType>(SURVEY_TABLE_NAME);

export async function getSurveyForUserIdArray(userIdList: UserId[]) {
  const chunks = prepareChunks(userIdList);
  const dds = chunks.map(async (chunkOfIds) => {
    const q = query(collection(SURVEY_TABLE_NAME), where('user', 'in', chunkOfIds));
    const ddDocs = await getDocs(q);

    const diaries: SurveyType[] = [];

    if (ddDocs.empty) {
      return diaries;
    }
    ddDocs.forEach((doc) => {
      const data = doc.data() as SurveyType;
      data.id = doc.id;
      diaries.push({ ...data });
    });

    return diaries;
  });
  return Promise.all(dds);
}
