import { collection, getDocs, orderBy, query, Timestamp, where } from '../models/dalaccess';
import {
  addNewFor,
  createNewFor,
  deleteDocumentFor,
  getAllFor,
  getAllQueryFor,
  getByIdArrayFor,
  getByIdFor,
  getByQueryFor,
  getNewIdFor,
  getRefByIdFor,
  getValidateTypeBaseFor,
  getValidateTypeBuilderFor,
  getValidateTypeFor,
  getValidateTypeNewFor,
  prepareChunks,
  updateDocumentFor,
} from './shared';
import { SESSIONS_TABLE_NAME } from './tableName';
import { UserId } from '../modeltypes/id';
import { SessionType, SessionTypeBase, SessionTypeBuilder, SessionTypeNew } from '../modeltypes/session';
import { idToString } from 'src/utility/GeneralUtilities';

// noinspection JSUnusedGlobalSymbols
export const getNewUserId = getNewIdFor<UserId>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const addNewSession = addNewFor<UserId, SessionTypeNew>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const createNewSession = createNewFor<UserId, SessionTypeBuilder>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getSessionRefById = getRefByIdFor<UserId>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getSessionById = getByIdFor<UserId, SessionType>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getSessionsByIdArray = getByIdArrayFor<UserId, SessionType>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getSessionsByQuery = getByQueryFor<SessionType>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllSessions = getAllFor<SessionType>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const getAllSessionsQuery = getAllQueryFor(SESSIONS_TABLE_NAME);

export const getSessionDataByUserId = async (userId: UserId) => {
  const sessionData = await getSessionsByQuery(where('uid', '==', idToString(userId)));
  console.log('returning sessionData: ', sessionData);
  return sessionData;
};

// noinspection JSUnusedGlobalSymbols
export const updateSession = updateDocumentFor<UserId, SessionTypeBase>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const deleteSession = deleteDocumentFor<UserId>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateSessionTypeBase = getValidateTypeBaseFor<SessionTypeBase>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateSessionTypeBuilder = getValidateTypeBuilderFor<SessionTypeBuilder>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateSessionType = getValidateTypeFor<SessionType>(SESSIONS_TABLE_NAME);

// noinspection JSUnusedGlobalSymbols
export const validateSessionTypeNew = getValidateTypeNewFor<SessionTypeNew>(SESSIONS_TABLE_NAME);

export async function getSessionsForUserIdArray(userIdList: UserId[], startDate?: Timestamp) {
  const chunks = prepareChunks(userIdList);
  const sessions = chunks.map(async (chunkOfIds) => {
    const q = query(
      collection(SESSIONS_TABLE_NAME),
      where('uid', 'in', chunkOfIds),
      startDate ? where('sessionStart', '>=', startDate) : where('sessionStart', '<=', new Date()),
      orderBy('sessionStart', 'desc'),
    );
    const sessionDocs = await getDocs(q);

    const sessions: SessionType[] = [];

    if (sessionDocs.empty) {
      return sessions;
    }
    sessionDocs.forEach((doc) => {
      const data = doc.data() as SessionType;
      data.id = doc.id;
      const validData = validateSessionType(data, false, true);
      sessions.push({ ...validData });
    });

    return sessions;
  });
  return Promise.all(sessions);
}

export async function getAllSessionsForUserIdArray(userIdList: UserId[]) {
  const chunks = prepareChunks(userIdList);
  const sessions = chunks.map(async (chunkOfIds) => {
    const q = query(collection(SESSIONS_TABLE_NAME), where('uid', 'in', chunkOfIds), orderBy('sessionStart', 'desc'));
    const sessionDocs = await getDocs(q);

    const sessions: SessionType[] = [];

    if (sessionDocs.empty) {
      return sessions;
    }
    sessionDocs.forEach((doc) => {
      const data = doc.data() as SessionType;
      data.id = doc.id;
      const validData = validateSessionType(data, false, true);
      sessions.push({ ...validData });
    });

    return sessions;
  });
  return Promise.all(sessions);
}
